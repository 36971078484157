.Comment-container {
    display: flex;
    flex-direction: row;
    margin: 10px 0 14px;
    position: relative;
    max-width: 500px;

    .popoi {
        color: #fff;
        text-shadow: -1px 0 0 #aaa;
        font-size: 40px;
        position: absolute;
        left: -26px;
        top: 50%;
        margin-top: -30px;
        display: none;

        &.show {
            display: block;
        }
    }

    &.selected {
        .bubble {
            .inner-bubble {
                background-color: #e2f3f5;
            }
        }
    }

    .avatar {
    }

    .bubble {
        .inner-bubble {
            -webkit-margin-start: 7px;
            -moz-margin-start: 7px;
            background-color: #f2f3f5;
            border-radius: 18px;
            box-sizing: border-box;
            color: #1c1e21;
            display: inline-block;
            max-width: 100%;
            position: relative;
            padding: 8px 10px;

            &:hover {
            }

            .name {
                color: #365899;
                cursor: pointer;
                text-decoration: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 1.38;
            }

            .content {
                line-height: 16px;
                font-size: 13px;
                word-wrap: break-word;
                white-space: normal;
                word-break: break-word;
                padding: 0;
                margin: 0;
            }

            .image {
                max-width: 500px;

                img {
                    width: 440px;
                    margin: 10px 0;
                }
            }
        }

        .actions {
            -webkit-padding-start: 18px;
            -moz-padding-start: 18px;
            font-size: 13px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            a {
                color: #365899;
            }

            .time {
                color: #606770;
            }
        }

        .likes {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

body.staging {
}

@media (max-width: 1050px) {
    .Comment-container {
        margin: 10px 0 14px;

        .popoi {
            &.show {
                display: none !important;
            }
        }

        .bubble {
            .inner-bubble {
                -webkit-margin-start: 3px;
                -moz-margin-start: 3px;
                border-radius: 18px;
                padding: 8px 10px;

                .name {
                    font-size: 16px;
                    line-height: 1.5;
                }

                .content {
                    line-height: 20px;
                    font-size: 15px;
                }
            }

            .actions {
                -webkit-padding-start: 18px;
                -moz-padding-start: 18px;
                font-size: 13px;
            }
        }
    }
}
