a {
    color: cyan;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &.no-link {
        color: inherit;
        text-decoration: none !important;
    }
}

h1 {
    font-weight: 200;

    span {
        font-size: 14px;
        color: palevioletred;
        margin-right: 10px;
    }
}

h2 {
    color: "#777";
}

h3 {
    color: "#777";
    font-size: 14px;
    margin: 15px 0 0;
    padding: 5px 0 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.relative {
        position: relative;
    }

    a,
    button {
        font-size: 10px !important;
    }
}

label {
    display: block;
    font-size: 12px;
    color: #666;
    // margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
        margin-right: 10px;
    }
}
input[type="checkbox"] {
    margin-left: 6px;
}

input[type="text"],
input[type="number"],
input[type="password"] {
    width: 200px;
    padding: 10px 10px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: "Rubik", sans-serif;
    margin: 5px 0 5px 10px;

    &.float {
        width: 70px;
        font-size: 14px;
        padding: 3px 5px;
    }

    &.long {
        width: 300px;
    }

    &.url {
        width: 400px;
    }

    &.rtl {
        direction: rtl;
    }

    &.ltr {
        direction: ltr;
    }
}

select {
    width: 240px;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    margin: 5px 0;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: "Rubik", sans-serif;
    margin-bottom: 20px;
}

textarea {
    width: 100%;
    flex: 1;
    padding: 10px 10px;
    font-weight: 400;
    font-size: 18px;
    margin: 5px 0;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: "Rubik", sans-serif;

    &.long {
        width: 700px;
        height: 300px;
    }

    &.rtl {
        direction: rtl;
    }

    &.ltr {
        direction: ltr;
    }
}

button,
.button {
    margin: 7px 0;
    padding: 6px 10px;
    background-color: white;
    border-radius: 3px;
    border: 1px solid gray;
    font-size: 16px;
    font-family: "Rubik", sans-serif;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none !important; // for a links
    color: #333;

    &.relative {
        position: relative;
    }

    &.link {
        color: dodgerblue;
        background-color: transparent;
        padding: 0;
        margin: 0;
        text-decoration: underline;
        border: none;
        font-size: 16px;
        display: inline;

        &.light {
            color: cyan;
        }

        &.red {
            color: brown;
        }

        &:hover {
            background-color: transparent;
        }
    }

    &:hover {
        background-color: #f3f3f3;
    }

    &:disabled {
        color: #aaa;
        border: 1px solid #aaa;
        cursor: default;

        &:hover {
            background-color: white;
        }
    }

    &.outline {
        border: 1px solid #eee;
        background-color: transparent;
        color: #eee;
    }

    &.inline {
        display: inline-block;
        margin: 0 5px;
    }
}

body.staging {
    button,
    .button {
        background-color: #444;
        border: none;
        color: white;

        &.link {
            color: white;
        }
    }
}
