.ToastMessage-container {
    background-color: white;
    width: 300px;
    min-height: 55px;
    margin: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .bar {
        width: 30px;
        background-color: green;
        position: relative;

        .filler {
            background-color: rgba(255, 255, 255, 0.15);
            width: 100%;
            position: absolute;
            bottom: 0;
            animation: filler 5s;
        }

        .icon {
            display: flex;
            align-items: flex-start;
            padding-top: 10px;
            color: white;
            opacity: 0.9;
            font-size: 13px;
            justify-content: center;
        }
    }

    .content {
        padding: 5px;
        position: relative;
        flex: 1;
        display: flex;
        padding: 10px;
        align-items: flex-start;

        .icon {
            position: absolute;
            padding: 4px;
            cursor: pointer;
            top: 0;
            left: 0;
            pointer-events: all;

            span {
                color: #666;
                font-size: 14px;
            }
        }
    }

    &.warning {
        .bar {
            background-color: orange;
        }
    }

    &.error {
        .bar {
            background-color: darkred;
        }
    }
}

@keyframes filler {
    0% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
}
