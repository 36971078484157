.EmailButton-container {
    background-color: #444;
    border: none;
    font-size: 20px;
    padding: 10px 20px;
    color: #eee;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: lighten(#444, 10%);
    }

    i {
        font-size: 24px;
        margin-left: 10px;
    }

    &.small {
        font-size: 14px;
        padding: 6px 10px;
        border-radius: 5px;

        i {
            font-size: 16px;
            margin-left: 5px;
        }
    }
}
