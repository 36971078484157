.PostHeader-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .details {
        -webkit-padding-start: 10px;
        -moz-padding-start: 10px;
        text-align: start;

        .name {
            color: #365899;
            cursor: pointer;
            text-decoration: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 1.38;
        }

        .date {
            color: #616770;
            font-size: 12px;
        }
    }
}

body.staging {
    .PostHeader-container {
        .details {
            .name {
                color: palevioletred;
            }

            .date {
                color: #aaa;
            }
        }
    }
}

@media (max-width: 1050px) {
    .PostHeader-container {
        .details {
            .name {
                font-size: 18px;
            }

            .date {
                font-size: 16px;
            }
        }
    }
}
