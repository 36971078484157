.ScreenRoot-container {
    flex: 1;

    .main {
        padding-left: 50%;

        .loader {
            margin: 50px auto;
        }
    }

    .panel {
        border-right: 2px solid gray;
        position: relative;
        background-color: #333;
        font-family: "Rubik", sans-serif;
        overflow: auto;
        display: flex;
        position: fixed;
        left: 0;
        width: 50%;
        bottom: 0;
        top: 0;
        overflow: auto;

        &.xrayEdit {
            background-color: darken(goldenrod, 40%);
        }
    }

    a {
        color: cyan;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .popoi {
        color: #fff;
        text-shadow: -1px 0 0 #aaa;
        font-size: 40px;
        position: absolute;
        left: -26px;
        top: 50%;
        margin-top: -30px;
        display: none;

        &.show {
            display: block;
        }
    }
}

body.staging {
    .panel {
        border-right: 2px solid #444;
    }
}

@media (max-width: 1050px) {
    .ScreenRoot-container {
        flex: 1;
        display: flex;

        .main {
            padding-left: 0;
            display: flex;
            flex: 1;
        }

        .panel {
            display: none;
        }

        .popoi {
            display: none;

            &.show {
                display: none;
            }
        }
    }
}
