.IconWithLabel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-margin-end: 10px;
    -moz-margin-end: 10px;

    span {
        font-size: 12px;
    }
}

@media (max-width: 1050px) {
    .IconWithLabel-container {
        span {
            font-size: 16px;
        }
    }
}
