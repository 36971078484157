.Project-container {
    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .body {
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 1000px) {
    .Project-container {
        .header {
            flex-direction: row;
        }

        .body {
            flex-direction: column;
        }
    }
}
