.ScreenHomeVolunteer-container {
    padding: 20px 20px 50px;

    .content {
        margin: 0 auto;
        max-width: 1050px;
    }

    h2 {
        margin-top: 10px;
    }

    h3 {
        font-size: 20px;
        font-weight: 200;
        padding: 0;
        margin: 0;
    }

    a {
        width: 200px;
    }

    strong {
        padding-left: 5px;
    }
}
