.Content-container {
  flex: 1;
  margin-left: 40px;
}

@media (max-width: 1000px) {
  .Content-container {
    margin-left: 0;
  }
}
