.TextualPost-container {
    padding: 12px 12px 0;
    background-color: #fff;
    border: 1px solid #dddfe2;
    border-radius: 3px;
    margin-bottom: 20px;
    position: relative;

    .reference {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .replies {
        &.sub {
            -webkit-margin-start: 45px;
            -moz-margin-start: 45px;
        }
    }
}

body.staging {
    .Post-container {
        background-color: #222;
        border: 1px solid #333;
        border-radius: 15px;
    }
}

@media (max-width: 1050px) {
    .TextualPost-container {
        padding: 20px;

        .replies {
            &.sub {
                -webkit-margin-start: 0;
                -moz-margin-start: 0;
            }
        }
    }
}
