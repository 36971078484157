@import "../../constants//colors.scss";

.CommentStats-container {
    display: flex;
    align-items: center;
    float: left;

    .item {
        display: flex;
        margin: 0 3px;

        .icon {
            border-left: none;
            display: flex;
            align-items: center;
            padding: 3px;
        }

        .badge {
            padding: 3px;
        }

        &:nth-child(1) {
            color: $green;
        }

        &:nth-child(2) {
            color: $red;
        }

        &:nth-child(3) {
            color: $yellow;
        }

        &:nth-child(4) {
            color: $blue;
        }
    }
}
