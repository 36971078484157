.VideoPost-container {
    padding: 12px 12px 0;
    background-color: #fff;
    border: 1px solid #dddfe2;
    border-radius: 3px;
    margin-bottom: 20px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 25px;

    .header {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .root {
        background-color: black;
    }

    .cc {
        padding: 30px 20px;
    }

    .lofs {
        display: none;
    }

    .play-pause {
        display: none;
    }

    .message {
        display: none;
    }
}

@media (max-width: 1050px) {
    .VideoPost-container {
        margin: 0;
        padding: 0;

        .header {
            padding: 5px 10px 0;
        }

        .cc {
            display: none;
        }

        .lofs {
            flex: 1;
            display: flex;
        }

        .message {
            background-color: #333;
            padding: 50px 0;
            color: #aaa;
            padding: 50px;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            text-align: center;
        }

        .play-pause {
            padding: 10px 0;
            background-color: #222;
            display: block;

            &:active {
                background-color: #111;
            }
        }
    }
}
