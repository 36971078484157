.RemoteImage-container {
    background-color: #555;
    position: relative;

    img {
        border-radius: 3px;
        width: 100%;
        animation-duration: 50ms;
    }

    .loader {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 40%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
    }
}
